@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .public-site-hero-container {
    position: relative;
	background-color: $gray-lightest;
    padding: 80px 0;

    .public-site-hero-wrapper {
        @include add-side-padding;
    }

    &:after {
        display: block;
        width: 100%;
        height: 40px;
        content: "";
        background-color: $gray-lightest;
        position: absolute;
        left: 0;
        bottom: -40px;
        z-index: -1;
    }

    @include mobile {
        padding: 40px 0;
    }
}