@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .highlight {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;

    .highlight-column {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .highlight-header {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2rem;
    }

    .media-wrapper {
        width: 500px;
        max-width: 50%;
        flex-shrink: 0;
        margin: auto 0;
    }

    &.highlight-reverse {
        flex-direction: row-reverse;
    }
}