@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .footer {
        background: $white;
        padding: 80px 0;
        margin: 0 auto;
        width: 100%;
    }

    .footer-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        align-items: center;
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $gray-light;
        margin: 2em 0;
    }

    .socials-wrapper {
        display: flex;
        align-items: center;
    }

    .social-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 29px;
        height: 29px;
        background-color: $text-primary;
        border-radius: 50%;
        margin: 4px 3px 2px;
    }

    .social-icon-linkedin {
        position: relative;
        width: 16px;
        height: 16px;
        background-color: $white;
    }

    .social-icon .social-icon-linkedin .MuiSvgIcon-root {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .social-icon .MuiSvgIcon-root {
        pointer-events: none;
    }

    .social-icon .social-icon-linkedin .MuiSvgIcon-root path {
        fill: $text-primary;
    }

    .socials-wrapper .MuiSvgIcon-root path {
        stroke: unset;
    }

    .social-icon .MuiSvgIcon-root path {
        stroke: unset;
        fill: $white;
    }

    .terms-and-policy-wrapper {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        gap: 24px;
    }

    @include mobile {
        .footer {
            padding: 40px 0;
        }
    }

    @include small-mobile {
        .footer-group {
            flex-direction: column;
            align-items: flex-start;
        }

        .terms-and-policy-wrapper {
            flex-direction: column;
        }
    }
}