@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .cta-block {
        border-radius: 40px;
        padding: 80px $side-padding;
        background-color: $black-lighter;
    }

    .cta-block-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
    }

    .cta-block-blue {
        background-color: $brand-blue;
    }

    .cta-block .accent {
        color: $brand-green;
    }

    .cta-group {
        display: flex;
        flex-direction: column;
        gap: 24px;
        color: $white;
        flex-basis: calc(50% - 24px);
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
    }

    .cta-group-description {
        gap: 16px;
    }

    .cta-group-full-width {
        flex-basis: 100%;
    }

    @include mobile {
        .login-cta-block.cta-block,
        .cta-block {
            padding: 40px $side-padding-mobile;
            border-radius: 24px;
            transition: padding 0.3s ease-in-out;
            gap: 40px;
        }
    }

    @include small-mobile {
        .cta-group {
            flex-basis: 100%;
        }

        .cta-group .form-input-group .MuiFormControl-root {
            flex-basis: 100% !important;
        }
    }
}