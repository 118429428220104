.cc-form .content.large {
    height: calc(100vh - 64px) !important;
    margin: 0px !important;
}

.cc-form  #form-scroller.large {
    height: 95% !important;
    padding-bottom: 20px !important;
}


.cc-form  .alignLeft {
    text-align: left;
}

.cc-form .width25p {
    width: 25%;
}

.cc-form .width30p {
    width: 30%;
}

.cc-form  .width35p {
    width: 35%;
}

.cc-form .width45p {
    width: 45%;
}

.cc-form .width65p {
    width: 65%;
}

.cc-form .width100p {
    width: 100%;
}

.cc-form .height10 {
    height: 10px;
}

.cc-form .flex1 {
    flex: 1;
}


.cc-form .paddingL0 {
    padding-left: 0px !important;
}

.cc-form .paddingL14 {
    padding-left: 14px;
}

.cc-form .paddingL30 {
    padding-left: 30px;
}

.cc-form .alignCenter {
    align-items: center;
}

.cc-form .justifyBetween {
    justify-content: space-between;
}

.cc-form .formWrapper {
    padding-right: 4vw;
    padding-left: 4vw;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #2A363D;
}

.cc-form .h1 {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 20px;
}

.cc-form .h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #2A363D;
    margin-bottom: 20px;
  }

.cc-form .MuiAccordionSummary-content {
    flex-grow: 0;
}

.cc-form .label {
    font-weight: 600 !important;
    /* color: #2a363c; */
}

.cc-form .subHeadingTitleFormat {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
        /* margin: 0; */
}

.cc-form .leftLabel {
    margin-right: 15px !important;
}

.cc-form .labelStatic {
    margin-bottom: 12px;
    /* color: #2a363c; */
}

.cc-form .fieldStatic {
    background-color: rgba(42, 54, 61, 0.1);
    border-radius: 3px;
}

/* .cc-form .labelIndent {
    margin-top: 10px;
    border-radius: 3px;
    /* border-left: 2px solid #E5E5E5; */ /* Wanted by the client? */
/* } */

.cc-form .hideBorder {
    box-shadow: none !important;
}

.cc-form .root.Mui-focused {
    border: 2px solid #28ADE3;
};
.cc-form .root.Mui-focused.MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.cc-form .accordionLabelWrap {
    justify-content: flex-start !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
}
.cc-form .accordionLabelWrap .MuiAccordionSummary-content {
    flex-grow: 0;
}

.cc-form .infohighlight {
    border-left: 2px solid #E5E5E5;
}

.cc-form .numberInputs input {
    padding: 10px 14px;
}

.cc-form .selects.sm,
.cc-form .selects.sm input {
    min-width: 100px;
}
.cc-form .selects.md,
.cc-form .selects.md input {
    min-width: 207px;
}

.cc-form .placeholder div {
    color: #a2a2a2 !important;
}

.cc-form .numberInputs.inColumns,
.cc-form .selects.inColumns {
    margin: 0 2.5px;
}

.cc-form .inputPadding14 input,
.cc-form .inputPadding14 .MuiOutlinedInput-input {
    text-align: left;
    padding: 14px;
}

.cc-form .selects.inColumns div {
    padding: 10px 14px;
}

.cc-form .radios {
    color: #28ADE3 !important;
}
.cc-form .radios:hover {
    background-color: #28aee30a !important;
}

.cc-form .checkBox {
    padding: 0 !important;
}
.cc-form .checkBox .MuiSvgIcon-root {
    font-size: 1.6rem !important;
}

.cc-form table {
    border-collapse: separate;
    /* border-spacing: 0px 10px; */
}

.cc-form table th {
    font-weight: 400;
    text-align: center;
}

.cc-form .reviewEditBtnWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #E5E5E5;
}

.cc-form .reviewEditBtns {
    background-color: rgba(103, 194, 221, 0.1) !important;
    border-radius: 3px;
    color: #28ADE3 !important;
    width: 71px;
    height: 43px;
}
.cc-form .reviewEditBtns:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.cc-form .weight400 {
    font-weight: 400 !important;
}
.cc-form .weight600 {
    font-weight: 600 !important;
}

.cc-form .marginT-40 {
    margin-top: -40px !important;
}

.cc-form .marginB0 {
    margin-bottom: 0px !important;
}

.cc-form .marginB10 {
    margin-bottom: 10px !important;
}

.cc-form .marginB15 {
    margin-bottom: 15px !important;
}

.cc-form .marginB20 {
    margin-bottom: 20px !important;
}

.cc-form .marginB30 {
    margin-bottom: 30px !important;
}

.cc-form .marginB40 {
    margin-bottom: 40px !important;
}

.cc-form .marginB50 {
    margin-bottom: 50px !important;
}

.cc-form .marginB60 {
    margin-bottom: 60px !important;
}


.cc-form .errorMessageColor {
    color: #f01616;
}
