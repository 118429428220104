@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .timeline-accordion {
        .timeline-accordion-year {
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            margin: auto 0;
            margin-right: 48px;
        }

        .timeline-accordion-label {
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;

            @include mobile {
                font-size: 24px;
            }
        }

        .timeline-accordion-body {
            margin-left: 88px;
            margin-right: 24px;

            img {
                width: 100%;
            }
        }
    }

    .MuiAccordion-root {
        border-radius: 24px !important;
        box-shadow: none;
    }

    .MuiAccordionSummary-root {
        padding: 0 24px;
        width: 100%;
    }

    .MuiAccordionDetails-root,
    .MuiAccordionSummary-content {
        margin: 24px 0;
    }

    .MuiAccordionDetails-root {
        margin-top: 0;
    }
}