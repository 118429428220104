#primary-nav .MuiDrawer-paper {
    background: #051129;
}

.primaryDrawer * {
    color: white;
}

.primaryNavHeader {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.primaryNavHeaderItem {
    padding-left: 0 !important;
}

.primaryNavHeaderItem .MuiListItemText-root span{
    padding-left: 10px;
}

.navList {
    color: white;
    padding: 0px !important;
}

.primaryDrawer .mainLogo {
    width: 36px;
    height: 36px;
    margin: 0px 14px;
}

.primaryDrawer.open .mainLogo {
    margin: 0px 9px 0px 16px;
}

.primaryDrawer .headerTitle {
    line-height: 24px;
    margin: 3px 0px;
}
.primaryDrawer .headerTitle span {
    font-size: 14px !important;
    font-family: 'Lora', serif !important;
    font-weight: 500 !important;
    /* line-height: 28px; */
    padding-left: 0px;
}

.primaryDrawer .headerTitle p {
    font-size: 12px !important;
    font-family: 'Mulish', serif !important;
    font-weight: 500 !important;
    /* line-height: 18.5px; */
    color: #ffffff80 !important;
    margin: 0px !important;
}

.menuNavIcon {
    width: 24px;
}

.primaryNavItem {
    padding: 16px 16px 16px 20px !important;
}

.primaryDrawer.open .primaryNavItem {
    padding: 16px !important;
}

.primaryNavIcon {
    padding-right: 20px !important;
}
.primaryDrawer.open .primaryNavIcon {
    padding-right: 17px !important;
}

.navText span {
    font-size: 14px !important;
    line-height: 17.5px !important;
    opacity: 0.5;
}
.navText.activeNav span {
    opacity: 1 !important;
}

.primaryBottomNavItem {
    padding: 8px 16px 8px 20px !important;
}
.primaryDrawer.open .primaryBottomNavItem {
    padding: 8px 16px !important;
}

.secondaryNavItem {
    padding: 8px 16px 8px 56px !important;
}

.secondaryDot {
    min-width: 0px !important;
}
.secondaryDot svg {
    margin: 0px;
}
.secondaryDot .dotIcon {
    width: 15px;
    height: 15px;
    /* margin-left: 5px; */
    opacity: 0.5;
}
.secondaryDot.activeDot .dotIcon {
    opacity: 1 !important;
}
.secondaryDot.activeDot .dotIcon circle {
    color: #44CEA5 !important;
}

.navText.secondaryNav {
    margin: 0px !important;
}
.navText.secondaryNav span {
    line-height: 24px !important;
    color: white;
    opacity: 0.5;
    padding-left: 10px !important;
    text-wrap: wrap !important;
}



.MuiListItemIcon-root {
    min-width: 0px !important;
    color: white !important;
    display: flex;
    align-items: center;
}

.description-header {
    font-weight: 500 !important;
}

.table-header {
    font-weight: 600 !important;
}