@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .logo-section {
        display: flex;
        flex-direction: column;
        gap: 48px;
        border-top: 1px solid $gray-2;
        padding-top: 80px;
        margin-top: 32px;

        @include mobile {
            padding-top: 40px;
            margin-top: 16px;
            gap: 24px;
        }
    }

    .logo-section-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;

        .title {
            font-size: 32px;
            font-weight: 500;
            flex-basis: calc(50% - 24px);
        }

        .description {
            font-size: 24px;
            font-weight: 500;
            line-height: 34px;
            flex-basis: calc(50% - 24px);
        }

        @include small-mobile {
            .title,
            .description {
                flex-basis: 100%;
            }
        }
    }

    .logo-section-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
    }
}