/* for the login page */

.checkboxForgotPassword {
  display: flex;
  justify-content: space-between;
}

.loginInputField
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #1957cd;
  border-width: 2px;
}

.loginInputField .MuiFormLabel-root.Mui-focused {
  color: #1957cd;
  
  
}
.loginAccountLink{
  margin:0 0 0 12px;
}

.lora-font {
  font-family: "Lora" !important;
}
.mulish-font {
  font-family: "Mulish" !important;
}

.loginInputField .MuiFormLabel-root {
  font-family: "Mulish" !important;
}
 label#email-textfield-label {
  font-family: "Mulish", sans-serif !important;
  font-size: 16px;
  color: #41484d;
}

