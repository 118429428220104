@import "../../../Pages/PublicSite/Variables.scss";

.publicSiteLayout .button-link {
    color: $brand-blue;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &.btn-black {
        color: $text-primary;
        text-decoration: underline;
    }
}