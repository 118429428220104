/*

This file declares all variables used
Currently only used for the public website

*/

/* Colors */
$black: #000000;
$white: #FFFFFF;
$brand-black: #171C20;
$brand-blue: #1957CD;
$brand-blue-light: #8CABE6;
$brand-green: #44CEA5;
$text-primary: #262F35;
$gray-dark: #41484D;
$gray-light: #DADCE0;
$gray-lighter: #E4E7EC;
$gray-lighter-1: #EBEBEB;
$gray-lightest: #FAF7F4;
$black-lighter: #051129;
$gray: #5E5E5E;
$gray-1: #B4BAB7;
$gray-2: #BCBCBC;

/* Style measurements */
$side-padding: 140px;
$side-padding-mobile: 1.2rem;
$max-width: 1440px;