@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .timeline-wrapper {
        display: flex;
        flex-direction: column;
        gap: 80px;
        padding: 80px;
        background-color: $gray-lightest;
        border-radius: 40px;

        @include mobile {
            gap: 40px;
            padding: 40px 16px;
        }

        .public-site-chart-wrapper {
            padding: 0;
        }
    }

    .timeline-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}