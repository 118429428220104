.public-site-dialog.public-site-modal {
    z-index: 1500;


    div *,
    h2 {
        font-family: "Mulish", sans-serif !important;
    }

    .MuiPaper-root {
        height: auto !important;
        max-height: calc(100% - 64px) !important;
        border-radius: 24px;
    }

    .MuiDialogTitle-root {
        font-size: 1.5rem;
        font-weight: 700;
    }
    h2 {
        padding: 24px 24px 16px;
    }

    .MuiDialogContent-root {
        font-size: 0.875rem;
        line-height: 20px;
        padding: 0px 24px 24px;
    }

    .MuiDialogActions-root {
        padding: 0px 24px 24px;
    }
}