@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .custom-select-dropdown {
    width: 100%;

    .MuiFormControl-root,
    .MuiInputBase-root {
        background-color: $white;
        width: 100%;
        border-radius: 100px;

        legend span {
            line-height: 0;
        }

        .MuiSvgIcon-root {
            right: 24px;
            color: $gray-dark;
        }

        .MuiSelect-select {
            padding: 12px 24px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0;
        border-color: none;
    }

    &.open {
        .MuiFormControl-root,
        .MuiInputBase-root {
            border-radius: 32px 32px 0 0;
            outline: 0;
        }
    }
}

.publicSiteLayout .custom-select-dropdown-menu {
    .MuiPaper-root {
        border-radius: 0 0 32px 32px;

        .MuiButtonBase-root {
            padding: 8px 24px;
        }
    }
}