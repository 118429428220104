.publicSiteLayout .login-cta-block {
    gap: 48px;
    padding: 80px;

    .cta-group {
        flex-direction: row;
        gap: 48px;
        justify-content: space-between;
        align-items: flex-start;
        flex-basis: 100%;
    }

    .title {
        font-size: 32px;
        line-height: normal;
    }

    .title,
    .description {
        flex-basis: calc(50% - 48px);
    }

    .description {
        font-size: 1.5rem;
        line-height: normal;
    }

    .img-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        img {
            max-width: 260px;
            width: 100%;
        }
    }

    .cta-group-content {
        align-items: center;
    }

    @media only screen and (max-width: 850px) {
        gap: 48px;

        .cta-group {
            gap: 24px;
            flex-direction: column;
        }

        .cta-group-content {
            gap: 48px;
        }

        .public-site-btn {
            width: 100%;
        }

        .description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
                    line-clamp: 3;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            flex-basis: auto;
        }

        .img-wrapper {
            width: 100%;
            justify-content: center;
        }
    }
}