@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .public-site-chart-wrapper {
        width: 100%;

        .MuiButtonBase-root[aria-label="add to reports"] {
            display: none;
        }

        .MuiCardHeader-content .MuiCardHeader-title {
            font-weight: 700;
            color: $text-primary;
        }

        .MuiCardContent-root {
            padding: 0 48px;
            padding-bottom: 48px !important;
        }

        .MuiCardHeader-root {
            padding: 48px;
            padding-bottom: 0;
        }

        .MuiTypography-body1 {
            margin-bottom: 16px;
            color: $gray;
        }

        text {
            font-family: "Mulish", sans-serif !important;
            font-weight: 600 !important;
        }

        // .graph1 {
        //     display: flex;
        //     align-items: center;
        //     width: 100%;

        //     > div {
        //         margin: 0 auto;
        //     }
        // }

        @include add-side-padding;
    }

    .public-site-chart-footer {
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
        padding: 6px 0;

        a {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: $brand-blue;
        }
    }
}