.ccNumberDisplay span div {
  /* font-size: 2.5em; */
  color: black;
  /* margin-top: -0.75em; */
}

#cc-dash .MuiTypography-h5 {
  font-size: 1rem;
}

.line {
  stroke: black !important;
}
.line.colorGrey{
  stroke: #949494 !important;
}
.line.strokeColor0 {
  stroke: #1957CD !important;
}
.line.strokeColor1 {
  stroke: #0F347B !important;
}
.line.strokeColor2 {
  stroke: #46ADE3 !important;
}

.divider {
  stroke: #E6E6E6 !important;
}

.y-axis path.domain {
  stroke: #000;
}

.ccNumberDisplay .MuiCardHeader-root {
  padding-bottom: 0px
}

.ccNumberDisplay .MuiCardContent-root {
  padding-top: 0px
}

.graph .tooltip {
  font-size: 16px !important;
  position: fixed;
  z-index: 9999;
  color: black;
  text-align: center;
  /* padding-top: 20px !important; */
  /* width: 150px; */
}

.graph .tooltip .percent {
  margin-top: 5px;
}

.graph text tspan,
.ccNumberDisplay .chart div text{
  /* font-size: 10px !important; */
  margin-top: 0em;
}

.horizontal-graph text tspan{
  font-size: 11px !important;
  margin-top: 0em;
}

.graph-stacked .tooltip {
  font-size: 16px !important;
  position: fixed;
  z-index: 9999;
  color: black;
  text-align: center;
  /* padding-top: 20px !important; */
  /* width: 150px; */
}

.graph-stacked .tooltip .percent {
  margin-top: 5px;
}

.graph-stacked .x-axis .tick text {
  font-size: 9px !important;
  margin-top: 0em;
}

/* #report-wrapper text tspan { */
  /* font-size: 8px !important; */ /* DK changes */
/* } */

/* #report-wrapper .raceagetext {
  font-size: 6px !important;
} */

.MuiDialog-paperWidthFalse {
  width: 80vw !important;
  overflow-y: scroll;
  overflow-x: scroll;
}
#form-scroller {
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  /* #form-scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #form-scroller::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  #form-scroller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: grey;
  } */
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .cc-form .MuiPaper-elevation1 {
    overflow-y: scroll;
  }
  .MuiDialog-paperScrollPaper {
    height: 100vh !important;
  }
  .MuiDialog-paperWidthFalse {
    width: 150vw !important;
}

.MuiDialog-paperScrollPaper {
  max-height: 75vh !important;
}

/* ::-webkit-scrollbar {
  width: 0px;
    background: transparent} /* make scrollbar transparent */

.cc-form .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  padding: 0px !important;
}

.histogramRef table {
  order: 1;
}

.histogramRef .pieChart {
  order: 2;
}

.histogramRef .barChart {
  order: 3;
}

}

.histogramRefReport .barChart {
  display: block;
}

.histogramRefReport .legend {
  display: block;
}

.histogramRefReport .pieChart {
  display: block;
}