.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1px;
}

text {
  fill: black;
  font-weight: bold;
  font-size: 14px;
}

.iParentText {
  font-size: 14px !important;
}

.xAxis .tick text {
  fill: black;
  font-size: 14px;
}
.grid .tick line {
  stroke: grey;
  stroke-dasharray: 5, 10;
  opacity: 0.7;
}
.grid path {
  stroke-width: 0;
}

.node circle {
  fill: #999;
}
.node--internal circle {
  fill: #555;
}
.node--internal text {
  /* parent datum(Dots) */
  font-size: 14px;
  text-shadow: 0 2px 0 #fff, 0 -2px 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff;
}
.node--leaf text {
  fill: white;
}
.ballG text {
  fill: white;
}

/* .shadow {
  -webkit-filter: drop-shadow(-1.5px -1.5px 1.5px #000);
  filter: drop-shadow(-1.5px -1.5px 1.5px #000);
} */
