@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .media-wrapper {
    border-radius: 40px;
    max-width: $max-width;
    margin: 0 auto;
    overflow: hidden;

    img {
        width: 100%;
    }

    @include mobile {
        border-radius: 20px;
    }
}