@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .public-site-select-dropdown {
    width: 100%;
    background-color: $black-lighter;
    border-radius: 40px;
    padding: 80px 0;

    .public-site-dropdown-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

        @include add-side-padding;
    }

    .select-dropdown-label {
        color: $white;
        font-size: 48px;
        line-height: normal;
        font-weight: 500;
        text-align: center;

        @include mobile {
            font-size: 32px;
        }
    }

    &.sticky {
        position: sticky;
        padding: 24px 0;
        top: 0;
        left: 0;
        z-index: 4;
        transition: top 0.2s ease-in;
        border-radius: 0;

        .public-site-dropdown-wrapper {
            flex-direction: row;
            gap: 40px;

            .select-dropdown-label {
                font-size: 32px;
                text-align: left;
                flex-shrink: 0;

                @include mobile {
                    flex-shrink: 1;
                }
            }
        }
    }
}