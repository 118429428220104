.checkboxLabel .MuiButtonBase-root{
    padding: 5px 9px !important;
}

.checkboxLabel span {
    font-weight: 300;
}

.MuiAutocomplete-popper {
    z-index: 9999;
    position: fixed;
}

.MuiChip-root {
    justify-content: space-between !important;
}