@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .public-site-map {
    width: 100%;

    @include small-mobile {
        padding: 0;
        margin: 0;
    }
}

.publicSiteLayout .public-site-map-wrapper {
    background-color: $gray-lightest;
    border-radius: 40px;

    .public-site-map-container {
        position: relative;
        border-radius: 0 0 40px 40px;
        overflow: hidden;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);

        .mapInfoWrapper {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1;

            div:last-child {
                display: none;
            }
        }

        .casemapinfo {
            top: 20px;
            left: 20px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            width: 318px;
            max-width: calc(100% - 40px);
            border-radius: 16px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

            // h2 {
            //     font-family: "Lora", serif !important;
            //     font-size: 32px !important;
            //     font-style: normal;
            //     font-weight: 500;
            //     line-height: normal;
            // }

            span {
                font-family: "Mulish", sans-serif !important;

                &:not(:first-child) {
                    font-size: 16px;
                    line-height: 24px;

                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }

                &:first-child {
                    margin: 16px 0 !important;
                }
            }
        }


        .casemapinfo {
            width: 225px;
        }

        .casemapinfo,
        .casemapinfo div {
            font-size: 13px;


        span {
            &:not(:first-child) {
                font-size: 1em;
                line-height: 1.5em;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }

            &:first-child {
                margin: 0em 0em 0.8em !important;
            }
        }

        .fontSize2em { /* * 16 = 32px */
            font-size: 2em !important;
        }
        .fontSize1-75em { /* * 16 = 28px */
            font-size: 1.75em !important;
        }
        .fontSize1-5em { /* * 16 = 24px */
            font-size: 1.5em !important;
        }
        .fontSize1-25em { /* * 16 = 20px */
            font-size: 1.25em !important;
        }
        .fontSize1em {
            font-size: 1em !important;
        }

        .fontSize24,
        .fontSize24 h2 {
            font-size: 24px !important;
        }
        .fontSize16 {
            font-size: 16px !important;
        }
        .fontSize12 {
            font-size: 12px !important;
        }
        .fontSize10 {
            font-size: 10px !important;
        }


        .lineHeight1-2em {
            line-height: 1.2em;
        }
        .lineHeight1-3em {
            line-height: 1.3em;
        }
        .lineHeight1-4em {
            line-height: 1.4em;
        }
        .lineHeight1-5em {
            line-height: 1.5em;
        }


        .fontWeight400,
        .fontWeight400 * {
            font-weight: 400 !important;
        }
        .fontWeight500,
        .fontWeight500 * {
            font-weight: 500 !important;
        }
        .fontWeight600,
        .fontWeight600 * {
            font-weight: 600 !important;
        }
        .fontWeight700,
        .fontWeight700 * {
            font-weight: 700 !important;
        }

        .totalText {
            font-weight: 700 !important;
        }
    }



        .mapLegendWrapper {
            position: absolute;
            bottom: 80px;

            .maplegend {
                padding: 16px;
                border-radius: 8px;

                p {
                    font-size: 10px;
                    line-height: normal;
                    font-weight: 600;
                    color: $text-primary;
                }

                > p {
                    font-size: 12px;
                    font-weight: 700;
                    margin-bottom: 8px !important;
                }
            }

            .legendButton {
                @include apply-button-styles;
                padding: 8px 16px;

                .MuiButton-label {
                    font-weight: 700;
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                }
            }
        }

        .mapContainer {
            height: 100% !important;
            margin-top: 0 !important;

            .mapboxgl-ctrl-logo {
                display: none;
            }
        }

        .mapboxgl-ctrl button .mapboxgl-ctrl-icon {
            box-sizing: border-box;
        }

        .mapboxgl-popup .mapboxgl-popup-content {
            border-radius: 8px;
            padding: 16px;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

            > div {
                width: auto !important;
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            h3 {
                margin: 0;
                font-size: 12px;
                line-height: normal;
                font-weight: 700;
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            p {
                margin: 0;
            }

            p, span {
                font-size: 10px;
                line-height: normal;
                font-weight: 600;
            }
        }
    }


    .public-site-map-description {
        padding: 48px;

        .map-header {
            font-size: 28px;
            font-weight: 700;
            line-height: normal;
        }

        .map-description {
            font-size: 22px;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.public-site-map-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    padding: 16px 0;

    a {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: $brand-blue;
    }

    @include small-mobile {
        padding: 1.2rem;
    }

    .cta-button-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }
}

.public-site-popper-main {
    .MuiChip-root {
        border-radius: 80px;
        font-size: 1.2rem;
        justify-content: center !important;
        height: auto;
        width: fit-content;
    }

    .MuiChip-label {
        padding: 0;
    }
}

.public-site-map-dialog {
    .MuiPaper-root {
        border-radius: 24px;
    }

    .MuiDialogTitle-root {
        padding: 24px 24px 16px;
    }

    .MuiDialogContent-root {
        padding: 0 24px;
    }

    .MuiDialogActions-root {
        padding: 24px;
    }

    .MuiButton-root {
        text-transform: unset;
    }

    .MuiButton-label {
        font-size: 16px;
        line-height: normal;
        font-weight: 700;
    }

    h2 {
        font-weight: 700;
    }

    .data-table,
    p {
        font-size: 14px;
        line-height: 20px;
    }

    p {
        margin: 16px 0;
    }
}
