.label-wrapper {
    margin-top: 15px;
    background-color: #EEF2F8;
   
  }
  
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding-top: 5px;
    align-items: center;
  }
  
  
  