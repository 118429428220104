@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .custom-table {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 32px;

        .row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 24px;
            border-top: 1px solid $gray-1;
            flex-wrap: wrap;
        }

        .column {
            display: flex;
            flex-direction: row;
            gap: 16px;
            flex-basis: calc(50% - 24px);
            padding-top: 0;

            @include small-mobile {
                flex-basis: 100%;
            }
        }

        .custom-table-icon {
            width: 24px;
        }

        .column-title {
            font-weight: 700;
        }

        @include small-mobile {
            gap: 40px;
            margin-top: 16px;
        }
    }
}