@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .banner {
        display: block;
        width: 100%;
        background: linear-gradient(90deg, $brand-blue 0%, $brand-green 100%);
    }

    .banner-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $white;
        padding: 12px $side-padding;
        max-width: $max-width;
        width: 100%;
        height: auto;
        margin: 0 auto;
        transition: padding 0.3s ease-in-out, height 0.1s ease-out;
    }

    .banner-hidden {
        pointer-events: none;
        opacity: 0;
    }

    .banner-hidden .banner-content {
        height: 0;
        min-height: 0;
        padding: 0;
        transition: padding 0.3s ease-in-out, height 0.1s ease-out;
    }

    .close-button {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .banner .MuiSvgIcon-root path {
        fill: $white;
    }

    @include mobile {
        .banner-content {
            padding: 12px $side-padding-mobile;
            transition: padding 0.3s ease-in-out;
        }

        .banner-hidden {
            padding: 0;
        }
    }
}
