@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .navbar {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding-top: 1.5rem;
        margin: 0 auto;
        gap: 8px;
        background-color: $white;
        z-index: 9;
    }

    .navbar-button-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .navbar-button-group,
    .tab-group {
        @include add-side-padding;
    }

    .breadcrumb {
        display: flex;
        align-items: center;
        text-align: left;
    }

    .breadcrumb .MuiSvgIcon-root {
        stroke-width: 0.5px;
    }

    .breadcrumb span {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        margin-left: 4px;
    }

    .tab-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .tab-option-list {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        background-color: $white;
    }

    .tab-option {
        position: relative;
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 500;
        padding-bottom: 1.5rem;
        text-decoration: inherit;
        color: inherit;
        text-align: left;
        white-space: nowrap;
    }

    .tab-option.active {
        font-weight: 700;
    }

    .tab-option-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), $text-primary;
    }

    .active-tab-name {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        white-space: nowrap;
        text-transform: none;
        letter-spacing: normal;
        cursor: pointer;
    }

    .menu-button {
        display: none;
        align-self: center;
        width: 32px;
        height: 32px;
        padding: 8px;
        box-sizing: border-box;
        position: relative;
        border-radius: 50%;
    }

    .menu-button .MuiSvgIcon-root {
        color: $gray-dark;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        stroke-width: 0.5px;
    }

    @include mobile {
        .navbar {
            padding-bottom: 1.5rem;
            gap: 0;
            align-items: center;
        }

        .menu-button {
            display: block;
        }

        .active-tab-name {
            display: none;
        }

        .tab-option-list {
            display: flex;
            position: fixed;
            top: 0;
            left: 100%;
            flex-direction: column;
            height: 100%;
            width: 100%;
            gap: 1.5rem;
            padding-left: $side-padding-mobile;
            transition: left 0.3s ease-in-out, padding 0.3s ease-in-out;
            z-index: 9;
        }

        .tab-option-list.expanded {
            left: 0;
            transition: left 0.3s ease-in-out;
        }

        .tab-option {
            padding: 0 1.2rem;
        }

        .tab-option-border {
            bottom: unset;
            top: 0;
            left: 0;
            height: 100%;
            width: 2px;
        }
    }
}
