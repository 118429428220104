@mixin add-side-padding {
    max-width: $max-width;
    margin: 0 auto;
    padding-left: $side-padding;
    padding-right: $side-padding;
    transition: padding 0.3s ease-in-out;

    @include mobile {
        padding-left: $side-padding-mobile;
        padding-right: $side-padding-mobile;
        transition: padding 0.3s ease-in-out;
    }
}

@mixin mobile {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin small-mobile {
    @media only screen and (max-width: 850px) {
        @content;
    }
}

@mixin apply-button-styles {
    display: flex;
    padding: 0 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    height: 48px;
    background-color: $brand-green;
    width: fit-content;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    color: $brand-black;

    &:hover {
        background-color: $brand-green;
    }

    &.btn-white {
        background-color: $white;
    }

    &.btn-black {
        background-color: $text-primary;
        color: $white;
    }

    .MuiButton-label {
        text-transform: none;
        font-weight: 700;
    }
}