.mulish-font {
  font-family: "Mulish", sans-serif !important;
}

.MuiDialog-paperWidthXl.MuiDialog-paperScrollPaper {
  max-width: 480px;
  border-radius: 12px;
}
.recoveryHeader {
  font-family: "Lora", sans-serif !important;
  font-weight: 500;
  font-size: 32px;
  padding: 24px 0 4px 0;
}
.recovery-email-label {
  font-family: "Mulish", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 24px;
}
.setPassword-header {
  margin: 0px;
  padding: 24px 0 4px 0;
  font-size: 32px;
  font-family: "Lora", sans-serif !important;
}

.setPassword-para {
  margin: 0px;
  font-family: "Mulish", sans-serif !important;
  font-size: 16px;
  padding-bottom: 24px;
}

button.continue-button .MuiButton-label {
  font-family: "Mulish", sans-serif !important;
}

.dividerLine {
  border: none;
  height: 1px;
  background-color: #e4e7ec;
  width: calc(100% + 48px);
  margin:  0 -24px;
  
}

.button-div {
  padding-top: 24px;
}

.success-para {
    font-family: "Mulish", sans-serif !important;
 
  font-size: 16px;
  color: "#000000";
  padding-bottom: 24px;
}
.success-para-heading {
    font-family: "Lora", sans-serif !important;
 
  font-size: 29px;
  color: "#000000";
  padding: 24px 0 4px 0;
}

.success-btn-div {
  padding-top: 24px;
}

label#email-textfield-label {
  font-family: "Mulish", sans-serif !important;
  font-size: 16px;
  color: #41484d;
}

.setPassword-input-password {
  margin-top: 24px !important;
}
.setPassword-input-conf {
  padding-bottom: 24px !important;
}
#setPassword-input-label {
  font-family: "Mulish" !important;
}
.setPassword-btn .MuiButton-label {
  font-family: "Mulish", sans-serif !important;
}
.success-btn .MuiButton-label {
  font-family: "Mulish", sans-serif !important;
}
