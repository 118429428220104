#dashboard .dashboard-title {
    font-size: 16px;
    padding-right: 16px;
}

.popper-main {
    z-index: 999;
    top: 9px !important;
  }

.popper {
    z-index: 999;
    top: 15px !important;
}

.filterButton {
    height: 100%;
    margin-left: 10px;
}

.legendButton {
    background-color: white !important;
}

.mapInfoWrapper {
    position: fixed;
    display: flex;
    top: 80px;
    right: 30px;
    z-index: 10;
}

#chart .MuiDrawer-paper {
   background-color: #F4F5F5;
   z-index: 889 !important;
}

#chart .primary-nav-list-item {
    color: black;
 } 

 #chart .hide-outline span{
    font-weight: 300 !important;
 }

.chartsLeftMenu .catListItem {
    padding: 16px;
}
.chartsLeftMenu .catListItem .MuiListItemText-root  {
    margin: 0px;
}
.chartsLeftMenu .catListItem .MuiListItemText-root span {
    line-height: 24px;
}
.chartsLeftMenu .listItem {
    padding: 8px 16px 8px 24px;
}
 .chartsLeftMenu .listText {
     margin: 0px 0px 0px 10px;
 }
 .chartsLeftMenu .listText span {
     font-size: 14px;
     font-weight: 500;
     line-height: 24px;
 }

 .smallXaxis svg {
    overflow: visible !important;
 }
 #chart .graph-stacked span{
    font-weight: 300 !important;
 }

 

 #chart .primary-nav-list-item {
     padding-bottom: 100px;
 }

 #chart-content .line {
    stroke: black;
}

#chart-content .divider {
    stroke: lightgray;
}



.services-border {
    width: 100%;
    height: 15px;
    background-color: #C7C7C7;
}



.fontSize text {
    font-size: 12px;
}