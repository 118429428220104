@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray-lightest;
        border-radius: 24px;
        flex-basis: calc(25% - 24px);
        padding: 24px;

        .logo-image {
            max-width: 100%;

            @include small-mobile {
                max-height: 100px;
            }
        }

        @include small-mobile {
            flex-basis: 100%;
        }
    }
}