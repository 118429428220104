@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .map-controls {
        // for state clicked btn
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .map-controls:not(.global-filter) {
        background-color: $white;
        width: 100%;
        border-radius: 40px 40px 0 0;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    }

    .map-controls.global-filter {
        @include add-side-padding;

        .filter-controls {
            background-color: $white;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        }

        &.sticky {
            position: sticky;
            top: 0;
            left: auto;
            z-index: 4;
            transition: top 0.2s ease-in;
        }
    }

    .filter-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 24px;

        .MuiButton-label {
            color: $text-primary;
            text-transform: none;
        }
    }
}

.public-site-filter-container {
    border-radius: 4px;

    > div {
        padding: 24px;
        overflow-y: auto;
        width: auto;
    }

    .checkboxLabel span {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-weight: 500 !important;
    }

    [class^=makeStyles-saveButton] {
        margin-top: 24px;
    }

    [class^=makeStyles-saveButton] .MuiButtonBase-root {
        @include apply-button-styles;
        background-color: $text-primary;
        color: $white;

        &:hover {
            background-color: $text-primary;
        }
    }

    .time-year-filter {
        width: 200px;
        padding: 8px 0 24px;

        .checkboxLabel {
            padding: 8px 12px;
        }

        [class^=makeStyles-checkboxes] {
            margin-left: 0;
        }

        .MuiFormControlLabel-root {
            margin: 0;
        }
    }
}