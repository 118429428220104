@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout .icon-list-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;

    @include add-side-padding;

    .icon-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        flex-basis: calc(25% - 16px);
        text-align: center;

        .icon {
            display: flex;
            align-items: center;
            background-color: $gray-lightest;
            width: 160px;
            height: 160px;
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
            justify-content: center;
            color: $brand-blue;
            margin-bottom: 16px;
        }
    }
}