@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.publicSiteLayout {
    .newsletter-cta-block .cta-block-wrapper {
        max-width: $max-width;
        margin: 0 auto;
    }

    .newsletter-cta-block .cta-group {
        .title {
            font-size: 3rem;
            line-height: normal;
        }

        .contact {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .MuiSvgIcon-root {
                color: $brand-green;
            }
        }

        .description-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0 16px;
        }
    }

    .MuiSnackbarContent-message {
        color: $white;
    }
}