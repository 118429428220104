/* .tick[transform="translate(0,220.5)"] * {
  display: none;
} */

/* .tick[transform="translate(0,150.5)"]
  text[transform="translate(-10,5)rotate(-45)"] {
  display: none;
} */

/* g[transform="translate(0,270)"] .tick > text {
  display: none;
} */

.tooltip {
  width: 250px;
}

.axis line,
.axis path {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
/* .axis text {
  font-size: 8px;
} */

.rectangle-group {
  text-anchor: middle;
  fill: grey;
}
.rectangle-group rect {
  stroke: black;
  stroke-width: 0.5;
}
.rectangle-group rect.noBorder {
  stroke-width: 0;
}
